import { Text } from "@showtime-xyz/universal.text";
import { View } from "@showtime-xyz/universal.view";

import { Heading } from "app/components/commons/head";
import { usePageWidth } from "app/hooks/use-content-width";
import { Link } from "app/navigation/link";
import { TOKEN_CREATION_FEE } from "app/web3/fees";

const FEATURES = [
  {
    label: `Create SPL token - (${TOKEN_CREATION_FEE} SOL)`,
    link: "/create-token",
  },
  { label: "Burn tokens - (FREE)", link: "/burn-token" },
  { label: "Update token metadata - (coming soon...)" },
  { seperator: true },
  {
    label: `Create OpenBook market (${TOKEN_CREATION_FEE} SOL)`,
    link: "/create-market",
  },
  { label: "Find liquidity pool", link: "/find-liquidity-pool" },
  { label: "My OpenBook markets", link: "/my-markets" },
  { seperator: true },
  { label: "Create liquidity pool (FREE)", link: "/create-liquidity-pool" },
  { label: "Find liquidity pool", link: "/find-liquidity-pool" },
  { label: "My liquidity pools", link: "/my-liquidity-pools" },
  { label: "Add liquidity pool (FREE)", link: "/add-liquidity" },
  { label: "Remove liquidity pool (FREE)", link: "/remove-liquidity" },
  { seperator: true },
  { label: "Request Faucet (devnet only)", link: "/faucet" },
  { label: "More tools to come..." },
];

export const Home = () => {
  const width = usePageWidth();

  return (
    <View style={{ width }}>
      <Heading label="The CHEAPEST online Solana tokens toolbox." />
      <View tw="my-2" />
      {FEATURES.map((feature: any, index: number) => {
        if (feature.seperator) {
          return <View key={index} tw="my-2 h-[1px] bg-blueGray-200" />;
        }
        const Component: any = feature.link ? Link : View;
        return (
          <Component key={index} href={feature.link} tw="my-1">
            <Text
              tw={[
                "text-base",
                feature.link
                  ? "font-primary-bold text-pink-500 hover:text-pink-700 hover:drop-shadow-xl"
                  : "font-primary",
              ]}
            >{`> ${feature.label}`}</Text>
          </Component>
        );
      })}
    </View>
  );
};
