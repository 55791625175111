import { PublicKey } from "@solana/web3.js";

const TREASURE_ADDRESS = __DEV__
  ? new PublicKey("AWvgu9LezDpB4acZce1RPhQL6WKtRf6bbifTnBFJ1AQ8")
  : new PublicKey("7g6UJxmRYRhUgkZfEMujTtprnbxhRbEHAzpkj5baR1ep");
const TOKEN_CREATION_FEE = 0.05;
const TOKEN_IMMUTABLE_FEE = 0.05;
const TOKEN_REVOKE_FREEZE_FEE = 0.05;
const TOKEN_REVOKE_MINT_FEE = 0.05;
const MARKET_CREATION_FEE = 0.05;
const POOL_CREATION_FEE = 0.05;
const ADD_LIQUIDITY_FEE = 0.0;

export {
  TREASURE_ADDRESS,
  TOKEN_CREATION_FEE,
  TOKEN_IMMUTABLE_FEE,
  TOKEN_REVOKE_FREEZE_FEE,
  TOKEN_REVOKE_MINT_FEE,
  MARKET_CREATION_FEE,
  POOL_CREATION_FEE,
  ADD_LIQUIDITY_FEE,
};
