import { APP_NAME } from "app/lib/constants";

export async function getServerSideProps(context) {
  return {
    props: {
      meta: {
        shortTitle: APP_NAME,
        title: [APP_NAME].join(" | "),
        description: [APP_NAME].join(" | "),
        image: "",
      },
    },
  };
}

export { default } from "app/pages/home";
